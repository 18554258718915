<template>
	<div class="department">
		<div class="actions">
			<div class="add" @click="openAddDialog()"><div class="el-icon-plus"></div> 添加部门</div>
		</div>
		<div class="table_tit">
			<div>序号</div>
			<div>部门名称</div>
			<div>部门编号</div>
			<div>部门人数</div>
			<div>部门状态</div>
			<div>操作</div>
		</div>
		<div v-loading="loading">
			<div class="department_list" v-if="department_list.length">
				<div class="item" v-for="(item,index) in department_list" :key="index">
					<div>{{index+1}}</div>
					<div>{{item.department_name}}</div>
					<div>{{item.number}}</div>
					<div>{{item.member_count}}人</div>
					<div>{{item.state ? '已启用' : '已停用'}}</div>
					<div class="action">
						<div class="btn" @click="openAddDialog('edit',item)">修改</div>
						<div class="btn" @click="deleteDepartment(item.department_id)">删除</div>
						<div class="btn" @click="changeDepartmentState(item.department_id,item.state)">{{item.state ? '停用' : '启用'}}</div>
						<div class="btn" @click="ViewEmployee(item.department_id)">查看员工</div>
					</div>
				</div>
			</div>
			<div class="empty_text" v-else>暂无部门</div>
			<div class="pager">
				<el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页" :current-page.sync="currentPage" :page-size.sync="pageSize" @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange" hide-on-single-page></el-pagination>
			</div>
		</div>
		<el-dialog :visible.sync="add_dialog" :show-close="false" class="add_dialog">
			<div class="add_content">
				<div class="tit">{{is_add ? '添加部门' :'修改部门信息'}}</div>
				<div class="condition">
					<div class="condition_item">
						<div class="left">
							*<div class="name">部门名称：</div>
						</div>
						<div class="right">
							<input type="text" placeholder="请输入部门名称" v-model="department_name">
						</div>
					</div>
					<div class="condition_item">
						<div class="left">
							*<div class="name">部门编号：</div>
						</div>
						<div class="right">
							<input type="text" placeholder="请输入部门名称" v-model="department_number">
						</div>
					</div>
					<div class="condition_item">
						<div class="left">
							<div class="name">上级部门：</div>
						</div>
						<div class="right">
							<el-cascader :show-all-levels="false" v-model="superior_department" :options="all_department" :props="{ checkStrictly: true,value:'department_id',label:'department_name',children:'_child',emitPath:false }" placeholder="请选择上级部门"  clearable></el-cascader>
						</div>
					</div>
				</div>
				<div class="btns">
					<div class="btn save" @click="addDepartment()">保存</div>
					<div class="btn" @click="add_dialog = false">返回</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { DepartmentPageList, deleteDepartment, DepartmentTree, addDepartment, modifyDepartment, changeDepartmentState } from "@/api/organization"
	export default {
		data() {
			return {
				department_list:[],
				total:0,
				currentPage: 1,
				pageSize: 10,
				loading:true,
				add_dialog:false,
				is_add:true,
				department_name:'',
				department_number:'',
				superior_department:0,
				all_department:[],
				modify_department_id:'',
			}
		},
		created() {
			this.getDepartmentList();
			this.getDepartmentTree();
		},
		methods: {
			ViewEmployee(id){
				this.$router.push('/member/employee_management?department_id='+id)
			},
			changeDepartmentState(id,state){
				if(state){
					//停用
					this.$confirm("您确定要停用该部门吗？", "提示", {
					    confirmButtonText: "确定",
					    cancelButtonText: "取消",
					    type: "warning"
					}).then(() => {
						changeDepartmentState({
							department_id:id,
							state:0
						})
						.then(res=>{
							if(res.code>=0){
								this.$message({message:'停用成功'})
								this.getDepartmentList();
							}else{
								this.$message.error(res.message)
							}
						})
					})
					.catch(err=>{
						this.$message.error(err.message)
					})
				}else{
					//启用
					this.$confirm("您确定要启用该部门吗？", "提示", {
					    confirmButtonText: "确定",
					    cancelButtonText: "取消",
					    type: "warning"
					}).then(() => {
						changeDepartmentState({
							department_id:id,
							state:1
						})
						.then(res=>{
							if(res.code>=0){
								this.$message({message:'启用成功'})
								this.getDepartmentList();
							}else{
								this.$message.error(res.message)
							}
						})
						.catch(err=>{
							this.$message.error(err.message)
						})
					})
					
				}
			},
			openAddDialog(mode='',department=''){
				this.department_name = '';
				this.department_number = '';
				this.superior_department = 0;
				this.modify_department_id = '';
				this.is_add = true;
				if(mode=='edit'){
					this.is_add = false;
					this.modify_department_id = department.department_id;
					this.department_name = department.department_name;
					this.department_number = department.number;
					this.superior_department = department.pid;
				}
				this.add_dialog = true;
			},
			addDepartment(){
				if(!this.department_name){
					this.$message.error('部门名称不能为空');
					return;
				}
				if(!this.department_number){
					this.$message.error('部门编号不能为空');
					return;
				}
				if(this.is_add){
					addDepartment({
						department_name:this.department_name,
						number:this.department_number,
						pid:this.superior_department
					})
					.then(res=>{
						if(res.code>=0){
							this.$message({message:'添加成功'})
							this.add_dialog = false;
							this.getDepartmentList();
						}else{
							this.$message.error(res.message)
						}
					})
					.catch(err=>{
						this.$message.error(err.message)
					})
				}else{
					//编辑
					modifyDepartment({
						department_name:this.department_name,
						number:this.department_number,
						pid:this.superior_department,
						department_id:this.modify_department_id
					})
					.then(res=>{
						if(res.code>=0){
							this.$message({message:'修改成功'})
							this.add_dialog = false;
							this.getDepartmentList();
						}else{
							this.$message.error(res.message)
						}
					})
					.catch(err=>{
						this.$message.error(err.message)
					})
				}
			},
			getDepartmentTree(){
				DepartmentTree()
				.then(res=>{
					if(res.code>=0){
						this.all_department = Object.values(res.data)
					}
				})
			},
			deleteDepartment(id){
				this.$confirm("您确定要删除该部门吗？", "提示", {
				    confirmButtonText: "确定",
				    cancelButtonText: "取消",
				    type: "warning"
				}).then(() => {
					deleteDepartment({
						department_id:id
					})
					.then(res=>{
						if(res.code>=0){
							this.$message({message:'删除成功'})
							this.getDepartmentList();
						}else{
							this.$message.error(res.message)
						}
					})
					.catch(err=>{
						this.$message.error(err.message)
					})
				})
			},
			handleCurrentPageChange(page) {
			    this.currentPage = page
				this.getDepartmentList();
			},
			handlePageSizeChange(size) {
			    this.pageSize = size
				this.getDepartmentList();
			},
			getDepartmentList() {
				this.loading = true;
				DepartmentPageList({
					page:this.currentPage,
					page_size:this.pageSize
				})
				.then(res=>{
					if(res.code>=0){
						this.department_list = res.data.list;
						this.total = res.data.count;
						this.loading = false;
					}
				})
				.catch(err=>{
					
				})
			}
		},
	}
</script>

<style lang="scss">
	.department{
		min-height: 730px;
		box-sizing: border-box;
		background-color: #fff;
		padding: 20px 20px 20px 18px;
		.add_dialog {
			.el-dialog__body{
				padding: 0;
				.add_content{
					padding: 8px 44px 41px;
					.tit{
						color: #111;
						font-size: 16px;
						line-height: 22px;
						font-weight: 600;
						margin-bottom: 24px;
					}
					.condition{
						padding-left: 33px;
						.condition_item{
							display: flex;
							align-items: center;
							margin-bottom: 20px;
							.left{
								display: flex;
								align-items: center;
								color: #E02020;
								font-size: 12px;
								line-height: 16px;
								min-width: 70px;
								margin-right: 2px;
								justify-content: flex-end;
								.name{
									margin-left: 5px;
									color: #222;
									font-size: 12px;
									line-height: 16px;
								}
							}
							.right{
								flex:1;
								input{
									width: 45%;
									outline: none;
									border: 1px solid #DBDCDC;
									padding: 8px 12px;
									font-size: 12px;
									line-height: 16px;
									color:#333;
									&::placeholder{
										color: #999;
									}
								}
								.el-cascader{
									line-height: 1;
									.el-input__inner{
										height: auto !important;
										border-radius: 0;
										width: 100%;
									}
									.el-input__suffix{
											.el-input__icon{
											line-height: 1;
										}
									}
								}
							}
						}
					}
					.btns{
						margin-top: 12px;
						display: flex;
						align-items: center;
						padding-left: 104px;
						.btn{
							cursor: pointer;
							margin-right: 24px;
							color: #333;
							font-size: 14px;
							line-height: 16px;
							padding: 9px 20px;
							border-radius: 2px;
							border: 1px solid #DBDCDC;
							&.save{
								background-color: #007AFF;
								color: #fff;
							}
						}
					}
				}
			}
		}
		.actions{
			display: flex;
			align-items: center;
			margin-bottom: 16px;
			.add{
				cursor: pointer;
				color: #fff;
				font-size: 13px;
				line-height: 18px;
				padding: 6px 13px;
				border-radius: 4px;
				display: flex;
				align-items: center;
				background-color: #00A98F;
				.el-icon-plus{
					font-size: 12px;
					margin-right: 3px;
					line-height: 12px;
				}
			}
		}
		.table_tit{
			background-color: #F7F8FA;
			border-radius: 4px;
			display: flex;
			align-items: center;
			padding: 16px 17px;
			>div{
				color: #222;
				font-size: 12px;
				line-height: 16px;
				&:first-child{
					width: 10%;
				}
				&:nth-child(2){
					width: 20%;
				}
				&:nth-child(3){
					width: 19%;
				}
				&:nth-child(4){
					// width: 20%;
					flex: 1;
				}
				&:nth-child(5){
					width: 17%;
				}
				&:nth-child(6){
					width: 17%;
				}
			}
		}
		.department_list{
			padding: 20px 17px 0;
			.item{
				display: flex;
				align-items: center;
				margin-bottom: 32px;
				>div{
					color: #55555D;
					font-size: 12px;
					line-height: 16px;
					&:first-child{
						width: 10%;
					}
					&:nth-child(2){
						width: 20%;
						box-sizing: border-box;
						padding-right: 10px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
					}
					&:nth-child(3){
						width: 19%;
					}
					&:nth-child(4){
						// width: 20%;
						flex: 1;
					}
					&:nth-child(5){
						width: 17%;
					}
					&:nth-child(6){
						width: 17%;
					}
				}
				.action{
					display: flex;
					align-items: center;
					white-space: nowrap;
					justify-content: space-between;
					.btn{
						cursor: pointer;
						color: #0B5CFF;
						&:last-child{
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
	.empty_text{
		text-align: center;
		margin-top:10px
	}
</style>