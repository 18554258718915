import http from "../utils/http"

//部门分页列表
export function DepartmentPageList(params) {
    return http({
        url: "/api/Dedepartment/getDepartmentPageList",
        data: params
    })
}
//删除部门
export function deleteDepartment(params) {
    return http({
        url: "/api/Dedepartment/deleteDepartment",
        data: params
    })
}
//添加部门
export function addDepartment(params) {
    return http({
        url: "/api/Dedepartment/add",
        data: params
    })
}
//修改部门信息
export function modifyDepartment(params) {
    return http({
        url: "/api/Dedepartment/modifyDepartment",
        data: params
    })
}
//停启用部门
export function changeDepartmentState(params) {
    return http({
        url: "/api/Dedepartment/stateChange",
        data: params
    })
}
//部门层级信息
export function DepartmentTree(params) {
    return http({
        url: "/api/Dedepartment/getDepartmentTree",
        data: params
    })
}
//机构分页列表
export function OrganizationPageList(params) {
    return http({
        url: "/api/Deinstitution/getInstitutionPageList",
        data: params
    })
}
//机构列表
export function OrganizationList(params) {
    return http({
        url: "/api/Deinstitution/getInstitutionList",
        data: params
    })
}
//添加机构
export function addOrganization(params) {
    return http({
        url: "/api/Deinstitution/add",
        data: params
    })
}
//修改机构
export function editOrganization(params) {
    return http({
        url: "/api/Deinstitution/editInstitution",
        data: params
    })
}
//删除机构
export function deleteOrganization(params) {
    return http({
        url: "/api/Deinstitution/deleteInstitution",
        data: params
    })
}
//权限树状结构
export function authorityTree(params) {
    return http({
        url: "/api/Deinstitution/getAuthority",
        data: params
    })
}
//员工列表
export function employeePageList(params) {
    return http({
        url: "/api/Childmember/getMemberPageList",
        data: params
    })
}
//停用启用员工
export function changeEmployeeStatus(params) {
    return http({
        url: "/api/Childmember/changeStatus",
        data: params
    })
}
//添加员工
export function addEmployee(params) {
    return http({
        url: "/api/Childmember/usernameRegister",
        data: params
    })
}
//修改员工信息
export function editEmployee(params) {
    return http({
        url: "/api/Childmember/editChildmember",
        data: params
    })
}
//重置密码
export function resetPassword(params) {
    return http({
        url: "/api/Childmember/modifypassword",
        data: params
    })
}
//询价单列表
export function inquiryList(params) {
    return http({
        url: "/api/Deinquiry/getInquiryPageList",
        data: params
    })
}
//询价概况
export function overviewData(params) {
    return http({
        url: "/api/Deinquiry/overview",
        data: params
    })
}
//询价单详情
export function inquiryDetail(params) {
    return http({
        url: "/api/Deinquiry/getInquiryInfo",
        data: params
    })
}
//供求单列表
export function supplyDemandPageList(params) {
    return http({
        url: "/api/desupplydemand/getSupplyDemandPageList",
        data: params
    })
}
//发布需求
export function addSupplydemand(params) {
    return http({
        url: "/api/desupplydemand/addSupplydemand",
        data: params
    })
}
//删除需求/供应记录
export function deleteSupplyDemand(params) {
    return http({
        url: "/api/desupplydemand/deleteSupplyDemand",
        data: params
    })
}